import { createRouter, createWebHistory } from 'vue-router'
import store from '../store'

const ifNotAuthenticated = (to, from, next) => {
  if (!store.getters.isAuthenticated) {
    next()
    return
  }
  next('/app')
}

const ifAuthenticated = (to, from, next) => {
  if (store.getters.isAuthenticated) {
    next()
    return
  }
  next({ path: '/login' })
}

const routes = [
  {
    path: '/',
    redirect: '/app'
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../layouts/LoginLayout.vue'),
    beforeEnter: ifNotAuthenticated,
    props: true
  },
  {
    path: '/app',
    name: 'app',
    beforeEnter: ifAuthenticated,
    component: () => import('../layouts/AppLayout.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
