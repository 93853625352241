import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import axios from 'axios'

import PrimeVue from 'primevue/config'
import Calendar from 'primevue/calendar'
import AutoComplete from 'primevue/autocomplete'
import Checkbox from 'primevue/checkbox'
import ColorPicker from 'primevue/colorpicker'
import Dropdown from 'primevue/dropdown'
import Editor from 'primevue/editor'
import InputMask from 'primevue/inputmask'
import InputNumber from 'primevue/inputnumber'
import InputText from 'primevue/inputtext'
import InputSwitch from 'primevue/inputswitch'
import Password from 'primevue/password'
import Button from 'primevue/button'
import Panel from 'primevue/panel'
import Card from 'primevue/card'
import Message from 'primevue/message'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import Toolbar from 'primevue/toolbar'
import Sidebar from 'primevue/sidebar'
import Accordion from 'primevue/accordion'
import AccordionTab from 'primevue/accordiontab'
import TabView from 'primevue/tabview'
import TabPanel from 'primevue/tabpanel'
import Menu from 'primevue/menu'
import PanelMenu from 'primevue/panelmenu'
import FileUpload from 'primevue/fileupload'

window.API_URL = 'https://rrc.forteholding.biz/api/'
// window.API_URL = 'http://127.0.0.1:8000/api/'
// window.API_URL = 'http://192.168.1.81:8000/api/'
const access_token = localStorage.getItem('access_token')

// TODO Is there a better way?
window.axios = axios.create({
  baseURL: window.API_URL
})

if (access_token) {
  window.axios.defaults.headers.common.Authorization = `Bearer ${access_token}`
}

function updateViewport () {
  if (window.orientation == 0) {
    this.document
      .querySelector('meta[name=viewport]')
      .setAttribute('content', 'width=570, user-scalable=no')
  } else {
    this.document
      .querySelector('meta[name=viewport]')
      .setAttribute('content', 'width=device-width, initial-scale=1')
  }
}

window.addEventListener('orientationchange', updateViewport)
window.addEventListener('resize', updateViewport)
window.dispatchEvent(new Event('resize'))

const app = createApp(App)
app.component('Calendar', Calendar)
app.component('AutoComplete', AutoComplete)
app.component('Checkbox', Checkbox)
app.component('ColorPicker', ColorPicker)
app.component('Dropdown', Dropdown)
app.component('Editor', Editor)
app.component('InputNumber', InputNumber)
app.component('InputText', InputText)
app.component('InputSwitch', InputSwitch)
app.component('Password', Password)
app.component('PButton', Button)
app.component('Panel', Panel)
app.component('Card', Card)
app.component('Message', Message)
app.component('DataTable', DataTable)
app.component('Column', Column)
app.component('Toolbar', Toolbar)
app.component('Sidebar', Sidebar)
app.component('Accordion', Accordion)
app.component('AccordionTab', AccordionTab)
app.component('Message', Message)
app.component('InputMask', InputMask)
app.component('TabView', TabView)
app.component('TabPanel', TabPanel)
app.component('Menu', Menu)
app.component('PanelMenu', PanelMenu)
app.component('FileUpload', FileUpload)
app.component('PImage', Image)

app
  .use(store)
  .use(router)
  .use(PrimeVue, {
    locale: {
      startsWith: 'Starts with',
      contains: 'Contains',
      notContains: 'Not contains',
      endsWith: 'Ends with',
      equals: 'Equals',
      notEquals: 'Not equals',
      noFilter: 'No Filter',
      lt: 'Less than',
      lte: 'Less than or equal to',
      gt: 'Greater than',
      gte: 'Greater than or equal to',
      dateIs: 'Date is',
      dateIsNot: 'Date is not',
      dateBefore: 'Date is before',
      dateAfter: 'Date is after',
      clear: 'Clear',
      apply: 'Apply',
      matchAll: 'Match All',
      matchAny: 'Match Any',
      addRule: 'Add Rule',
      removeRule: 'Remove Rule',
      accept: 'Да',
      reject: 'Нет',
      choose: 'Выбрать',
      upload: 'Загрузить',
      cancel: 'Отмена',
      dayNames: [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday'
      ],
      dayNamesShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
      dayNamesMin: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
      monthNames: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December'
      ],
      monthNamesShort: [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec'
      ],
      today: 'Сегодня',
      weekHeader: 'Wk',
      firstDayOfWeek: 0,
      dateFormat: 'mm/dd/yy',
      weak: 'Говно',
      medium: 'Средний',
      strong: 'Отличный',
      passwordPrompt: 'Введите пароль',
      emptyFilterMessage: 'Нет результата',
      emptyMessage: 'Нет доступных опций'
    }
  })
  .mount('#app')
