<template>
  <div :class="['fullscreen-overlay', { hidden: !visible }]">
    <div class="inner">
      <button class="close" @click="$emit('close')"></button>
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FullScreenOverlay',
  props: ['visible'],
  watch: {
    visible () {
      document.body.style.overflow = this.visible ? 'hidden' : 'auto'
    }
  }
}
</script>

<style lang="scss">
.fullscreen-overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2000;
  width: 100%;
  height: 100%;
  background-color: #2f2f2f;

  .inner {
    padding: 20px;
  }

  .close {
    position: absolute;
    top: 22px;
    right: 45px;
    width: 43px;
    height: 43px;
    background: #c82349;
    text-indent: -9999px;
    overflow: hidden;
    border: none;
    border-radius: 50%;
    z-index: 5000;

    &:hover {
      cursor: pointer;
    }

    &::before,
    &::after {
      position: absolute;
      content: "";
      top: 50%;
      left: 50%;
      height: 2px;
      width: 20px;
      background: #fff;
      margin-left: -10px;
      margin-top: -1px;
      -webkit-transition: all 0.5s ease;
      -o-transition: all 0.5s ease;
      transition: all 0.5s ease;
    }

    &::before {
      transform: rotate(-45deg);
    }

    &::after {
      content: "";
      transform: rotate(45deg);
    }
  }
}

.hidden {
  display: none;
}
</style>
