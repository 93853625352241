<template>
  <div>
    <fs-overlay :visible="overlayIsVisible" @close="overlayIsVisible = false">
      <iframe id="holding_frame" src="https://forteholding.biz/popup"> </iframe>
    </fs-overlay>
    <router-view :key="$route.path" @show-site="showSiteSelector($event)" />
  </div>
</template>

<script>
// import 'primevue/resources/themes/md-light-indigo/theme.css'
import 'primevue/resources/primevue.min.css'
import 'primeflex/primeflex.min.css'
import 'primeicons/primeicons.css'
import FullScreenOverlay from './components/FullScreenOverlay.vue'

export default {
  name: 'App',
  components: { 'fs-overlay': FullScreenOverlay },
  created () {
    axios.interceptors.response.use(undefined, function (err) {
      return new Promise(function (resolve, reject) {
        if (err.status === 401 && err.config && !err.config.__isRetryRequest) {
          this.$store.dispatch(AUTH_LOGOUT)
        }
        throw err
      })
    })
  },
  data () {
    return {
      overlayIsVisible: false
    }
  },
  methods: {
    showSiteSelector (id) {
      this.overlayIsVisible = true
      const frame = window.frames[0]
      frame.postMessage(id, '*')
    }
  }
}
</script>

<style lang="scss">
@import "scss/breakpoints";
@import "scss/mixins";

@font-face {
  font-family: "dinprobold";
  src: url("/static/fonts/dinprobold.woff2");
}

@font-face {
  font-family: "dinproregular";
  src: url("/static/fonts/dinproregular.woff2");
}

@font-face {
  font-family: "fontawesome";
  src: url("/static/fonts/fontawesome-webfont.woff2");
}

body {
  margin: 0;
  font-family: "dinproregular";
  overflow-y: scroll;
  -webkit-font-smoothing: antialiased;
}

a {
  text-decoration: none;
}

.p-datatable-wrapper::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

.p-datatable-wrapper::-webkit-scrollbar-track {
  background-color: #ebebeb;
  border: 1px solid white;
}

.p-datatable-wrapper::-webkit-scrollbar-thumb {
  background-color: #b7b7b7;
  border-radius: 3px;
}

#app {
  font-family: "dinproregular";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  overflow: hidden;

  .wrapper {
    max-width: 1390px;
    margin: 0 auto;
    display: flex;
    padding: 0 40px;
    flex-wrap: wrap;

    @include media-breakpoint-up(lg) {
      padding: 0 50px;
      flex-wrap: nowrap;
    }
  }
}

#holding_frame {
  height: 2450px;
  width: calc(100% - 40px);
  border: none;
  @include media-breakpoint-up(lg) {
    height: 1250px;
  }
}

.p-inputtext:enabled:focus {
  box-shadow: inset 0 0 0 1px #c82349, inset 0 0 0 1px #c82349,
    inset 0 0 0 1px #c82349, inset 0 0 0 1px #c82349;
}

.p-inputtext {
  border: 0;
  border-bottom: 2px solid #d8d8d8;
  margin-bottom: 8px;
  border-radius: 0;
  padding: 12px 0;
  color: #363636;
  box-shadow: none !important;
  font-size: 20px;
  font-family: "dinproregular";
  outline: none !important;
  transition: all ease-out 0.4s;

  &::placeholder {
    color: #d8d8d8;
  }

  &:enabled:focus,
  .p-inputtext:enabled:hover {
    border-color: #c6274b;
    border-radius: 0;
  }
}

.p-button {
  border-radius: 0;
  padding: 10px 60px 18px 40px;
  background-color: #c6274b;
  color: white;
  font-size: 22px;
  border: 1px transparent solid;
  transition: all 0.5s linear;

  &:hover {
    background-color: white;
    color: #c6274b;
    border: 1px #c6274b solid;
  }
}

.p-checkbox {
  width: 15px;
  height: 15px;
}

.p-checkbox-box {
  width: 100%;
  height: 100%;
  background-color: #cccccc;
}
</style>
